@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 300;
  src: local("Source Sans Pro Light"),
    url("./fonts/SourceSansPro-Black.otf") format("truetype");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: local("Source Sans Pro Regular"),
    url("./fonts/SourceSansPro-Regular.otf") format("truetype");
}

body {
  margin: 0;
  font-family: "Source Sans Pro Light", sans-serif;
  background: #f7f9fd;
}
